import myfaucet from "../assets/fire-hydrant.svg";

// NOTE: Using inline styling demo but prefer styled-components or CSS classes in real app
export const Faucet = () => {
 
  return (

      <img
        src={myfaucet}
        alt="Faucet Foxes"
        width="95px"
      />
   
  
  );
};