import heart from "../assets/heart.png";

export type HeartProps = {
  onClick: () => void;
};

// NOTE: Using inline styling demo but prefer styled-components or CSS classes in real app
export const Heart = (props: HeartProps) => {
  const { onClick } = props;
  return (
      <img
      onClick={onClick}
        src={heart}
        alt="Heart"
        style={{
          cursor:"pointer",
          padding:"5px",
          position:"relative",
          height:"7vh"
        }}
      />
   
  
  );
};