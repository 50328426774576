import { useState, useEffect, useRef } from "react";
import { PulseLoader } from 'react-spinners';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@mui/material';
import faucetgroup from "../assets/faucetfoxesgroup.json";

type TwinFinderFaucetProps = {
  todisplay: string,
  available: string[],
  totalresults: number,
  faucetvar: boolean,
  passedFunction: () => void,
  getFox: (e) => void 
}

type TraitList = {
  name: string;
  link: string;
  img: string;
  imgid: string;
  trait1: string;
  trait2: string;
  trait3: string;
  trait4: string;
  trait5: string;
  trait6: string;
  trait7: string;
  title: string;
}
type Twins = {
  twin1: TraitList;
  twin2: TraitList;
}

const TwinFinderFaucet = ({ available, faucetvar, todisplay, totalresults, passedFunction, getFox}: TwinFinderFaucetProps) => {
  //loading for twins
  const [loading, setLoading] = useState<boolean>(true);

  //setnumresults
  const [numsixtrait, setNumSixTrait] = useState<number | string>("?");
  const [numseventrait, setNumSevenTrait] = useState<number | string>("?");

  //set variables to send to child twin displayer
  const [sixtwins, setSixTwins] = useState<Twins[]>([]);
  const [seventwins, setSevenTwins] = useState<Twins[]>([]);

  //useRefs
  const didMount = useRef(false);

  useEffect(
    () => {
      let sixtraittwins = [];
      let seventraittwins = [];
      let sixtraittwinstemp = [];
      let seventraittwinstemp = [];
      let ggg = faucetgroup;
      let rrr = todisplay;
      if (rrr) {
        let ppp = JSON.parse(rrr)!;
        let foxcount = 0;
        let foxlength = ppp.length;

        //get total length and set num results
        for (let i = 0; i < foxlength; i++) {
          if (ppp[i] !== undefined) if (ppp[i].origin.data.map.subTypeData.collectionId === "1611d956f397caa80b56bc148b4bce87b54f39b234aeca4668b4d5a7785eb9fa_0") {
            foxcount++;
          }
        }

        //get twins
        for (let d = 0; d < foxlength; d++) {
          for (let i = 0; i < foxlength; i++) {
            //check 1st fox for bg twins
            let traitsincommon = 0;

            if (ppp[d].origin.data.map.name === ppp[i].origin.data.map.name) {
              //do nothing
            } else {
              if (ppp[d].origin.data.map.subTypeData.traits[0].value === ppp[i].origin.data.map.subTypeData.traits[0].value) {
                traitsincommon++;
              }
              if (ppp[d].origin.data.map.subTypeData.traits[1].value === ppp[i].origin.data.map.subTypeData.traits[1].value) {
                traitsincommon++;
              }
              if (ppp[d].origin.data.map.subTypeData.traits[2].value === ppp[i].origin.data.map.subTypeData.traits[2].value) {
                traitsincommon++;
              }
              if (ppp[d].origin.data.map.subTypeData.traits[3].value === ppp[i].origin.data.map.subTypeData.traits[3].value) {
                traitsincommon++;
              }
              if (ppp[d].origin.data.map.subTypeData.traits[4].value === ppp[i].origin.data.map.subTypeData.traits[4].value) {
                traitsincommon++;
              }
              if (ppp[d].origin.data.map.subTypeData.traits[5].value === ppp[i].origin.data.map.subTypeData.traits[5].value) {
                traitsincommon++;
              }
              if (ppp[d].origin.data.map.subTypeData.traits[6].value === ppp[i].origin.data.map.subTypeData.traits[6].value) {
                traitsincommon++;
              }

              //push fox pair to appropriate array 
              if (traitsincommon === 0) {
                //do nothing
              }
              if (traitsincommon === 1) {
                //do nothing
              }
              if (traitsincommon === 2) {
                //do nothing
              }
              if (traitsincommon === 3) {

              }
              if (traitsincommon === 4) {
                //do nothing
              }
              if (traitsincommon === 5) {
              }
              if (traitsincommon === 6) {
                let match = 0;
                for (let g = 0; g < sixtraittwins.length; g++) {
                  if (sixtraittwins[g].twin2 === ppp[d] && sixtraittwins[g].twin1 === ppp[i]) {
                    match = 1;
                  }
                }
                if (match === 1) {
                  //do nothing
                } else {
                  sixtraittwins.push({ twin1: ppp[d], twin2: ppp[i] })
                }
              }
              if (traitsincommon === 7) {
                let match = 0;
                for (let g = 0; g < seventraittwins.length; g++) {
                  if (seventraittwins[g].twin2 === ppp[d] && seventraittwins[g].twin1 === ppp[i]) {
                    match = 1;
                  }
                }
                if (match === 1) {
                  //do nothing
                } else {
                  seventraittwins.push({ twin1: ppp[d], twin2: ppp[i] })
                }
              }
            }

          }
        }
        //display twins
        setNumSevenTrait(seventraittwins.length * 2)
        setNumSixTrait(sixtraittwins.length * 2)

        if (seventraittwins.length * 2 === 0) {
          setLoading(false)
        }
        if (sixtraittwins.length * 2 === 0) {
          setLoading(false)
        }

        //display 7 trait twins
        //display 7 trait twins
        let sevenlength = seventraittwins.length;
        for (let i = 0; i < sevenlength; i++) {
          // fox1
          seventraittwinstemp.push({ twin1: { img: "https://ordfs.network/content/" + seventraittwins[i].twin1.origin.outpoint }, twin2: { img: "https://ordfs.network/content/" + seventraittwins[i].twin2.origin.outpoint } })
          seventraittwinstemp[i].twin1.name = seventraittwins[i].twin1.origin.data.map.name;
          seventraittwinstemp[i].twin1.link = "https://alpha.1satordinals.com/outpoint/" + seventraittwins[i].twin1.origin.outpoint + "/inscription";
          seventraittwinstemp[i].twin1.imgid = seventraittwins[i].twin1.origin.outpoint;
          seventraittwinstemp[i].twin1.trait1 = seventraittwins[i].twin1.origin.data.map.subTypeData.traits[0].value;
          seventraittwinstemp[i].twin1.trait2 = seventraittwins[i].twin1.origin.data.map.subTypeData.traits[1].value;
          seventraittwinstemp[i].twin1.trait3 = seventraittwins[i].twin1.origin.data.map.subTypeData.traits[2].value;
          seventraittwinstemp[i].twin1.trait4 = seventraittwins[i].twin1.origin.data.map.subTypeData.traits[3].value;
          seventraittwinstemp[i].twin1.trait5 = seventraittwins[i].twin1.origin.data.map.subTypeData.traits[4].value;
          seventraittwinstemp[i].twin1.trait6 = seventraittwins[i].twin1.origin.data.map.subTypeData.traits[5].value;
          seventraittwinstemp[i].twin1.trait7 = seventraittwins[i].twin1.origin.data.map.subTypeData.traits[6].value;

          //get group member title by image id in foxgroup 
          for (let k=0; k<ggg.group.length; k++){
            if(ggg.group[k]["origin.outpoint"] === seventraittwinstemp[i].twin1.imgid){
              seventraittwinstemp[i].twin1.title = ggg.group[k].foxData.foxName;
            }
          }
        

          //fox2
          seventraittwinstemp[i].twin2.name = seventraittwins[i].twin2.origin.data.map.name;
          seventraittwinstemp[i].twin2.link = "https://alpha.1satordinals.com/outpoint/" + seventraittwins[i].twin2.origin.outpoint + "/inscription";
          seventraittwinstemp[i].twin2.imgid = seventraittwins[i].twin2.origin.utpoint;
          seventraittwinstemp[i].twin2.trait1 = seventraittwins[i].twin2.origin.data.map.subTypeData.traits[0].value;
          seventraittwinstemp[i].twin2.trait2 = seventraittwins[i].twin2.origin.data.map.subTypeData.traits[1].value;
          seventraittwinstemp[i].twin2.trait3 = seventraittwins[i].twin2.origin.data.map.subTypeData.traits[2].value;
          seventraittwinstemp[i].twin2.trait4 = seventraittwins[i].twin2.origin.data.map.subTypeData.traits[3].value;
          seventraittwinstemp[i].twin2.trait5 = seventraittwins[i].twin2.origin.data.map.subTypeData.traits[4].value;
          seventraittwinstemp[i].twin2.trait6 = seventraittwins[i].twin2.origin.data.map.subTypeData.traits[5].value;
          seventraittwinstemp[i].twin2.trait7 = seventraittwins[i].twin2.origin.data.map.subTypeData.traits[6].value;
                    //get group member title by image id in foxgroup 
                    for (let k=0; k<ggg.group.length; k++){
                      if(ggg.group[k]["origin.outpoint"] === seventraittwinstemp[i].twin2.imgid){
                        seventraittwinstemp[i].twin2.title = ggg.group[k].foxData.foxName;
                      }
                    }
        }

        setSevenTwins(seventraittwinstemp);

        //display 6 trait twins
        let sixlength = sixtraittwins.length;
        for (let i = 0; i < sixlength; i++) {
          // fox1
          sixtraittwinstemp.push({ twin1: { img: "https://ordfs.network/content/" + sixtraittwins[i].twin1.origin.outpoint }, twin2: { img: "https://ordfs.network/content/" + sixtraittwins[i].twin2.origin.outpoint } })
          sixtraittwinstemp[i].twin1.name = sixtraittwins[i].twin1.origin.data.map.name;
          sixtraittwinstemp[i].twin1.link = "https://alpha.1satordinals.com/outpoint/" + sixtraittwins[i].twin1.origin.outpoint + "/inscription";
          sixtraittwinstemp[i].twin1.imgid = sixtraittwins[i].twin1.origin.outpoint;
          sixtraittwinstemp[i].twin1.trait1 = sixtraittwins[i].twin1.origin.data.map.subTypeData.traits[0].value;
          sixtraittwinstemp[i].twin1.trait2 = sixtraittwins[i].twin1.origin.data.map.subTypeData.traits[1].value;
          sixtraittwinstemp[i].twin1.trait3 = sixtraittwins[i].twin1.origin.data.map.subTypeData.traits[2].value;
          sixtraittwinstemp[i].twin1.trait4 = sixtraittwins[i].twin1.origin.data.map.subTypeData.traits[3].value;
          sixtraittwinstemp[i].twin1.trait5 = sixtraittwins[i].twin1.origin.data.map.subTypeData.traits[4].value;
          sixtraittwinstemp[i].twin1.trait6 = sixtraittwins[i].twin1.origin.data.map.subTypeData.traits[5].value;
          sixtraittwinstemp[i].twin1.trait7 = sixtraittwins[i].twin1.origin.data.map.subTypeData.traits[6].value;
                    //get group member title by image id in foxgroup 
                    for (let k=0; k<ggg.group.length; k++){
                      if(ggg.group[k]["origin.outpoint"] === sixtraittwinstemp[i].twin1.imgid){
                        sixtraittwinstemp[i].twin1.title = ggg.group[k].foxData.foxName;
                      }
                    }

          // //fox2
          // sixtraittwinstemp.push({twin2: {img: "https://ordfs.network/content/" + sixtraittwins[i].twin2.outpoint}})

          // sixtraittwinstemp[i].twin2.img = "https://ordfs.network/content/" + sixtraittwins[i].twin2.outpoint;
          sixtraittwinstemp[i].twin2.name = sixtraittwins[i].twin2.origin.data.map.name;
          sixtraittwinstemp[i].twin2.link = "https://alpha.1satordinals.com/outpoint/" + sixtraittwins[i].twin2.origin.outpoint + "/inscription";
          sixtraittwinstemp[i].twin2.imgid = sixtraittwins[i].twin2.origin.outpoint;
          sixtraittwinstemp[i].twin2.trait1 = sixtraittwins[i].twin2.origin.data.map.subTypeData.traits[0].value;
          sixtraittwinstemp[i].twin2.trait2 = sixtraittwins[i].twin2.origin.data.map.subTypeData.traits[1].value;
          sixtraittwinstemp[i].twin2.trait3 = sixtraittwins[i].twin2.origin.data.map.subTypeData.traits[2].value;
          sixtraittwinstemp[i].twin2.trait4 = sixtraittwins[i].twin2.origin.data.map.subTypeData.traits[3].value;
          sixtraittwinstemp[i].twin2.trait5 = sixtraittwins[i].twin2.origin.data.map.subTypeData.traits[4].value;
          sixtraittwinstemp[i].twin2.trait6 = sixtraittwins[i].twin2.origin.data.map.subTypeData.traits[5].value;
          sixtraittwinstemp[i].twin2.trait7 = sixtraittwins[i].twin2.origin.data.map.subTypeData.traits[6].value;
                              //get group member title by image id in foxgroup 
                              for (let k=0; k<ggg.group.length; k++){
                                if(ggg.group[k]["origin.outpoint"] === sixtraittwinstemp[i].twin2.imgid){
                                  sixtraittwinstemp[i].twin2.title = ggg.group[k].foxData.foxName;
                                }
                              }
        }
        setSixTwins(sixtraittwinstemp);

        setLoading(false);
        passedFunction();
      }
    },
    [todisplay] // with dependency: run every time variable changes
  )
  //fox taken alert
  const taken = () => {
    alert("Faucet Fox already taken. Try another fox.")
  };


  return (

    <div className="TwinFinder">
      <div className="H3WrapperNoMargin">
        <h3>TwinFinder - 7/7 Traits<br /><span className="WhiteResults">Results: {numseventrait} / {totalresults}</span></h3>
      </div>

      <div className="CenterLoader">
        {loading && (
          <>
            <PulseLoader color="#ffffff" />
          </>
        )}
          {seventwins &&
          <>
            {seventwins.map(function (data) {
              return (
        <div key={uuidv4()} className="TwinWrapper">
            <div className="TitleDiv">
                <div className="TwinTitle">7/7 Trait TwinFinder</div>
            </div>
            <div className="TwinDiv">
            <li><a target="blank"
                            href={data.twin1.link}><img src={data.twin1.img}
                        className="seventraitfoxes"
                        id={data.twin1.imgid} /></a>
                          <span className="FoxTitle"><a target="blank"
                          href={data.twin1.link}>{data.twin1.title}</a>
                        </span>
                        {/* {faucetvar && (available.includes(data.twin1.imgid)) &&
    <Button variant="contained" data-title={data.twin1.title} data-trait1={data.twin1.trait1} data-trait2={data.twin1.trait2} data-trait3={data.twin1.trait3} data-trait4={data.twin1.trait4} data-trait5={data.twin1.trait5} data-trait6={data.twin1.trait6} data-trait7={data.twin1.trait7} data-imgid={data.twin1.imgid} data-link={data.twin1.link} data-img={data.twin1.img} data-name={data.twin1.name} onClick={getFox} sx={{color: "#fefefe", margin: "10px"}}>Get Fox</Button>
      }
      {faucetvar && !(available.includes(data.twin1.imgid)) &&
        <Button className="ButtonDisabled" sx={{ color: "#8b8b8b" }} onClick={taken}>Taken</Button>
      } */}
      <br />  
                    <div className="twintraitslabels">Name:<br />Background:<br />Fox:<br />Body:<br />Mouth:<br />Head:<br />Eyes:<br />Item:
                    </div>
                    <div className="twintraits">{data.twin1.name}<br />{data.twin1.trait1}<br />{data.twin1.trait2}<br />{data.twin1.trait3}<br />{data.twin1.trait4}<br />{data.twin1.trait5}<br />{data.twin1.trait6}<br />{data.twin1.trait7}</div>
                </li>
                <li><a target="blank"
                            href={data.twin2.link}><img src={data.twin2.img}
                        className="seventraitfoxes"
                        id={data.twin2.imgid} /></a>
                                                  <span className="FoxTitle"><a target="blank"
                          href={data.twin2.link}>{data.twin2.title}</a>
                        </span>
                        {/* {faucetvar && (available.includes(data.twin2.imgid)) &&
                    <Button variant="contained" data-title={data.twin2.title} data-trait1={data.twin2.trait1} data-trait2={data.twin2.trait2} data-trait3={data.twin2.trait3} data-trait4={data.twin2.trait4} data-trait5={data.twin2.trait5} data-trait6={data.twin2.trait6} data-trait7={data.twin2.trait7} data-imgid={data.twin2.imgid} data-link={data.twin2.link} data-img={data.twin2.img} data-name={data.twin2.name} onClick={getFox} sx={{color: "#fefefe", margin: "10px"}}>Get Fox</Button>
                      }
                      {faucetvar && !(available.includes(data.twin2.imgid)) &&
                        <Button className="ButtonDisabled" sx={{ color: "#8b8b8b" }} onClick={taken}>Taken</Button>
                      } */}
      <br />  
                    <div className="twintraitslabels">Name:<br />Background:<br />Fox:<br />Body:<br />Mouth:<br />Head:<br />Eyes:<br />Item:
                    </div>
                    <div className="twintraits">{data.twin2.name}<br />{data.twin2.trait1}<br />{data.twin2.trait2}<br />{data.twin2.trait3}<br />{data.twin2.trait4}<br />{data.twin2.trait5}<br />{data.twin2.trait6}<br />{data.twin2.trait7}</div>
                </li>
              </div>
              </div>
              )
            })}
          </>
        }
      </div>
  
      <div className="H3WrapperNoMargin">
        <h3>TwinFinder - 6/7 Traits<br /><span className="WhiteResults">Results: {numsixtrait} / {totalresults}</span></h3>
      </div>

      <div className="CenterLoader">
        {loading && (
          <>
            <PulseLoader color="#ffffff" />
          </>
        )}
        {sixtwins &&
          <>
            {sixtwins.map(function (data) {
              return (
        <div key={uuidv4()} className="TwinWrapper">
            <div className="TitleDiv">
                <div className="TwinTitle">6/7 Trait TwinFinder</div>
            </div>
            <div className="TwinDiv">
            <li><a target="blank"
                            href={data.twin1.link}><img src={data.twin1.img}
                        className="sixtraitfoxes"
                        id={data.twin1.imgid} /></a>
                                                  <span className="FoxTitle"><a target="blank"
                          href={data.twin1.link}>{data.twin1.title}</a>
                        </span>
                        {/* {faucetvar && (available.includes(data.twin1.imgid)) &&
    <Button variant="contained" data-title={data.twin1.title} data-trait1={data.twin1.trait1} data-trait2={data.twin1.trait2} data-trait3={data.twin1.trait3} data-trait4={data.twin1.trait4} data-trait5={data.twin1.trait5} data-trait6={data.twin1.trait6} data-trait7={data.twin1.trait7} data-imgid={data.twin1.imgid} data-link={data.twin1.link} data-img={data.twin1.img} data-name={data.twin1.name} onClick={getFox} sx={{color: "#fefefe", margin: "10px"}}>Get Fox</Button>
      }
      {faucetvar && !(available.includes(data.twin1.imgid)) &&
        <Button className="ButtonDisabled" sx={{ color: "#8b8b8b" }} onClick={taken}>Taken</Button>
      } */}
             <br />
                    <div className="twintraitslabels">Name:<br />Background:<br />Fox:<br />Body:<br />Mouth:<br />Head:<br />Eyes:<br />Item:
                    </div>
                    <div className="twintraits">{data.twin1.name}<br />{data.twin1.trait1}<br />{data.twin1.trait2}<br />{data.twin1.trait3}<br />{data.twin1.trait4}<br />{data.twin1.trait5}<br />{data.twin1.trait6}<br />{data.twin1.trait7}</div>
                </li>
                <li><a target="blank"
                            href={data.twin2.link}><img src={data.twin2.img}
                        className="sixtraitfoxes"
                        id={data.twin2.imgid} /></a>
                                                  <span className="FoxTitle"><a target="blank"
                          href={data.twin2.link}>{data.twin2.title}</a>
                        </span>

                      {/* {faucetvar && (available.includes(data.twin2.imgid)) &&
                    <Button variant="contained" data-title={data.twin2.title} data-trait1={data.twin2.trait1} data-trait2={data.twin2.trait2} data-trait3={data.twin2.trait3} data-trait4={data.twin2.trait4} data-trait5={data.twin2.trait5} data-trait6={data.twin2.trait6} data-trait7={data.twin2.trait7} data-imgid={data.twin2.imgid} data-link={data.twin2.link} data-img={data.twin2.img} data-name={data.twin2.name} onClick={getFox} sx={{color: "#fefefe", margin: "10px"}}>Get Fox</Button>
                      }
                      {faucetvar && !(available.includes(data.twin2.imgid)) &&
                        <Button className="ButtonDisabled" sx={{ color: "#8b8b8b" }} onClick={taken}>Taken</Button>
                      } */}
      <br /> 

                    <div className="twintraitslabels">Name:<br />Background:<br />Fox:<br />Body:<br />Mouth:<br />Head:<br />Eyes:<br />Item:
                    </div>
                    <div className="twintraits">{data.twin2.name}<br />{data.twin2.trait2}<br />{data.twin2.trait2}<br />{data.twin2.trait3}<br />{data.twin2.trait4}<br />{data.twin2.trait5}<br />{data.twin2.trait6}<br />{data.twin2.trait7}</div>
                </li>
              </div>
              </div>
              )
            })}
          </>
        }
      </div>
    </div>

  )
};

export default TwinFinderFaucet;