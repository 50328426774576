import live from "../assets/live.png";

export type LiveProps = {

};

// NOTE: Using inline styling demo but prefer styled-components or CSS classes in real app
export const WeLive = (props: LiveProps) => {
  return (
      <img
        src={live}
        alt="Live"
        style={{
          padding:"5px",
          position:"relative"
        }}
      />
   
  
  );
};