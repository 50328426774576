
import { useState } from "react";


const FooterHome = () => {

 
  return (
    <div className="Footer">
      <br />
        <br />
        <a className="FooterLink" target="blank" href="https://github.com/yours-org/yours-wallet">Yours Wallet GitHub</a><br />
        <a className="FooterLink" href="https://foxplorer.org/privacy-policy-and-terms-of-service">Privacy Policy and Terms of Service</a> 
        <br />
        <br />
©2024 foxplorer.org
<br />
        <br />
    </div>
   
  )
};

export default FooterHome;