import { useState, useEffect, useRef } from "react";
import { FaucetPandaConnectButton } from "../components/FaucetPandaConnectButton";
import { FoxplorerLogo } from "../components/FoxplorerLogo";
import { FoxplorerLogoHome } from "../components/FoxplorerLogoHome";
import { MyFox } from "../components/MyFox";
import { Faucet } from "../components/Faucet";
import { ActualFaucet } from "../components/ActualFaucet";
import { MyOtherFox } from "../components/MyOtherFox";
import { ThreeCircles } from 'react-loader-spinner';
import { XLogoRight } from "../components/XLogoRight";
import { DonateFoxes } from "../components/DonateFoxes"
import { XLogo } from "../components/XLogo";
import CommunityFaucetFilters from "../components/CommunityFaucetFilters";
import FooterHome from "../components/FooterHome";
import MyCountdown from '../components/MyCountdown';
import CreateGroupText from '../components/CreateGroupText';
import { FaRegCopy } from "react-icons/fa6";
import {
  usePandaWallet,
  Addresses
} from "panda-wallet-provider";
import { fabClasses } from "@mui/material";
import TemporaryDrawer from "../components/Drawer";

type CommunityFaucetProps = {

}

export const CommunityFaucet = ({ }: CommunityFaucetProps) => {
  //loading for twins
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false); 
  const [preloaded, setPreLoaded] = useState<boolean>(true); 
  //use yours
  const wallet = usePandaWallet();
  const [pubKey, setPubKey] = useState<string | undefined>();
  const [addresses, setAddresses] = useState<Addresses | undefined>();
  const [ordaddress, setOrdAddress] = useState<string>();
  const [myordaddress, setMyOrdAddress] = useState<string | undefined>(""); 

    //ordinalsstring for Foxes Child Component
    const [ordinalsstring, setOrdinalsString] = useState<string | undefined>();

      //get fox loading screen
  const [setgettingfox, setGettingFox] = useState<boolean>(false);

    //filters var => search results
    const [faucetvar, setFaucetVar] = useState<boolean>(true);
    const [myvar, setMyVar] = useState<boolean>(false);
    const [demovar, setDemoVar] = useState<boolean>(false);

    //countdown completed var
    const [complete, setComplete] = useState<boolean>(false);

      //pass loading function to child
  const setCompleted = () => {
    setComplete(true);
  }

      // hide extra info with showgetfoxscreen
      const passedFunctionFoxLoadingScreen = (state: boolean) => {
        setGettingFox(state);
      }

  //useRefs
  const didMount1 = useRef(false);
  const didMount2 = useRef(false);
  const didMount3 = useRef(false);
  useEffect(() => {
 

      setOrdAddress("1MicPBPE36mafsMPQXqgcF9Bt1NpwHHnvd")
  }, [])

    //go home 
    const goToIndex = async () => {
      window.location.href = "https://foxplorer.org/";
    };

      // get ord address
  useEffect(() => {
    if (!didMount2.current) {
      didMount2.current = true;
      return;
    }
    console.log(addresses.ordAddress)
    setMyOrdAddress(addresses.ordAddress)   
    
    // sendUtxos()
    // getUtxos()
  }, [addresses]);

  // async function sendUtxos(){
  //   const paymentParams:any = [
  //     {
  //       satoshis: 200,
  //       address: "16r9y9yE3X5HHkBjsrpsr5Zo4sxaathXCy",
  //     },
  //     {
  //       satoshis: 200,
  //       address: "16r9y9yE3X5HHkBjsrpsr5Zo4sxaathXCy",
  //     },
  //     {
  //       satoshis: 200,
  //       address: "16r9y9yE3X5HHkBjsrpsr5Zo4sxaathXCy",
  //     },
  //     {
  //       satoshis: 200,
  //       address: "16r9y9yE3X5HHkBjsrpsr5Zo4sxaathXCy",
  //     },
  //     {
  //       satoshis: 200,
  //       address: "16r9y9yE3X5HHkBjsrpsr5Zo4sxaathXCy",
  //     },
  //     {
  //       satoshis: 200,
  //       address: "16r9y9yE3X5HHkBjsrpsr5Zo4sxaathXCy",
  //     },
  //     {
  //       satoshis: 200,
  //       address: "16r9y9yE3X5HHkBjsrpsr5Zo4sxaathXCy",
  //     },
  //     {
  //       satoshis: 200,
  //       address: "16r9y9yE3X5HHkBjsrpsr5Zo4sxaathXCy",
  //     },
  //     {
  //       satoshis: 200,
  //       address: "16r9y9yE3X5HHkBjsrpsr5Zo4sxaathXCy",
  //     },
  //     {
  //       satoshis: 200,
  //       address: "16r9y9yE3X5HHkBjsrpsr5Zo4sxaathXCy",
  //     },
  //     {
  //       satoshis: 200,
  //       address: "16r9y9yE3X5HHkBjsrpsr5Zo4sxaathXCy",
  //     },
  //     {
  //       satoshis: 200,
  //       address: "16r9y9yE3X5HHkBjsrpsr5Zo4sxaathXCy",
  //     },
  //     {
  //       satoshis: 200,
  //       address: "16r9y9yE3X5HHkBjsrpsr5Zo4sxaathXCy",
  //     },
  //     {
  //       satoshis: 200,
  //       address: "16r9y9yE3X5HHkBjsrpsr5Zo4sxaathXCy",
  //     },
  //     {
  //       satoshis: 200,
  //       address: "16r9y9yE3X5HHkBjsrpsr5Zo4sxaathXCy",
  //     }
  //   ];
    
  //   try {
  //       const { txid, rawtx } = await wallet.sendBsv(paymentParams);
  //       console.log(txid);
  //       // f2fc518036d96c956c30b995b4b0a70d6008b4b7ef666f7c913b2a79ab57d679
  //   } catch (err) {
  //       console.log(err);
  //   }
  // }

  //   async function getUtxos(){

    
  //     try {
  //       const ordinals = await wallet.getOrdinals();
  //       console.log(ordinals);
  //       console.log(ordinals.length);
  //       for(let i = 0;i<ordinals.length;i++){
  //        const transferParams = {
  //         address: "1HSKSSgeMi8C21txWd7Lg1MnyhifepLkuG",
  //         origin: ordinals[i].origin.outpoint,
  //         outpoint: ordinals[i].outpoint,
  //        }
  //        const txid = await wallet.transferOrdinal(transferParams);
  //        console.log(txid);
  //       }
  //       // Returns an array of ordinal objects
  //   } catch (err) {
  //       console.log(err);
  //   }
  // }

        // get ord address
        useEffect(() => {
          if (!didMount3.current) {
            didMount3.current = true;
            return;
          }

            setTimeout(() => {
              setLoading(false)
              setLoaded(true)
      }, 2000);
       
        }, [myordaddress]);

      // get ordinals string
      useEffect(() => {
        if (!didMount1.current) {
          didMount1.current = true;
          return;
        }
        let result;
        if(ordaddress){
          result = ordaddress.trim();
        }

        //fetch
        fetch("https://ordinals.gorillapool.io/api/txos/address/" + result + "/unspent?limit=100000", {
          method: "GET",
        })
          .then((response) => response.json())
          .then((data) => {
            if((data.message)){
              setLoading(false);
              alert("Error fetching foxes. Please refresh and try again.");
            }else{
            // console.log(data)
            setOrdinalsString(JSON.stringify(data))
            setPreLoaded(false)

            }
          })
          .catch((error) => console.log(error));
  
  
      }, [ordaddress]);


    //handle get adresses
    const handleGetAddresses = async () => {
      const addrs = await wallet.getAddresses();
      if (addrs) setAddresses(addrs);
    };

  //handle connect
  const handleConnect = async () => {
    setLoading(true);
    setMyOrdAddress("")
    if (!wallet.connect) {
      window.open(
        "https://github.com/Panda-Wallet/panda-wallet#getting-started-alpha",
        "_blank"
      );
      setLoading(false);
      return;
    }

    const key = await wallet.connect();
    if (key) {
      setPubKey(key);
          setTimeout(() => {
            handleGetAddresses()
    }, 1000);

    }
    if (!key) {
      wallet.disconnect()
      alert("Make sure you are signed-in to Yours Wallet and try again.")
      setLoading(false);
      return
    }
  };

  return (

    <div className="App">
                  {preloaded && (
        <>
                  <header className="App-header">
                   
                  <FoxplorerLogoHome onClick={goToIndex} />
            <div className="LoaderHeight">

                <ThreeCircles color="black" height="50"
                  width="50" />
            </div>
            <MyOtherFox /> <ActualFaucet />
            </header>
        </>
      )}
                        {!preloaded && (
        <>

        <TemporaryDrawer />
          <div className="Topbar">
            <FoxplorerLogo onClick={goToIndex} />
            <a target="blank" href="https://twitter.com/yours_foxplorer"><XLogoRight /></a>
          </div>

          <div id="Faucet">
<br />
              
          {!loaded && (
              <>
          {!loading && (
              <>
                        
                        <div className="Yours">       
          <FaucetPandaConnectButton onClick={handleConnect} />
               </div>
                
              </>
             
            )}         

            {loading && (
              <>
                 <span className="Required">
                <ThreeCircles color="white" height="50"
                  width="50" />
                </span>
              </>
            )}
            </>
          )}

{loaded && (
              <>
            <span className="Required">  

            <span className="Demo"><b>Connected</b></span>
            <br />
                        Your ordinals address:<br />
                       {myordaddress}
               </span>
              </>
            )}
                                {!setgettingfox && (
              <>
        <div className="Heading"><br /><Faucet /><br /><span className="Demo">Community Faucet</span><br /><span className="SmallerFont">Add <a className="White" href="https://x.com/FoxesPixel"><u>Pixel Foxes</u></a> to the faucet:<br /><span className="DonateLink" onClick={() => {navigator.clipboard.writeText("1MicPBPE36mafsMPQXqgcF9Bt1NpwHHnvd")}}><FaRegCopy /> 1MicPBPE36mafsMPQXqgcF9Bt1NpwHHnvd</span></span>
        </div>
        </>
            )}

        </div>

          <CommunityFaucetFilters setgettingfox={setgettingfox} passedFunctionFoxLoadingScreen={passedFunctionFoxLoadingScreen} complete={complete} ordinalsstring={ordinalsstring} myordinalsaddress={myordaddress} faucetvar={faucetvar} myvar={myvar} demovar={demovar} />
          {!setgettingfox && (
              <>
          <FooterHome />
          </>
            )}
          {/* <CreateGroupText groupordinalsstring={ordinalsstring} /> */}
        
          </>
      )}
    </div>
    

  )
  
};
