import React, { useState, useEffect, useRef } from "react";
import { PandaConnectButton } from "../components/PandaConnectButton";
import Form from "../components/Form";
import { DemoButton } from "../components/DemoButton";
import { FoxplorerLogo } from "../components/FoxplorerLogo";
import { FoxplorerLogoHome } from "../components/FoxplorerLogoHome";
import { MyFox } from "../components/MyFox";
import { NormalFaucet } from "../components/NormalFaucet";
import { MyOtherFox } from "../components/MyOtherFox";
import { ThreeCircles } from 'react-loader-spinner';
import { XLogoRight } from "../components/XLogoRight";
import { XLogo } from "../components/XLogo";
import Filters from "../components/Filters";
import Footer from "../components/Footer";
import TemporaryDrawer from "../components/Drawer";
import FooterHome from "../components/FooterHome";
import CreateGroupText from '../components/CreateGroupText';
import CreateJsonForAll from '../components/CreateJsonForAll';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";


import {
  usePandaWallet,
  Addresses
} from "panda-wallet-provider";

import demojson from "../assets/demo3069.json";
import { Drawer } from "@mui/material";

type ActivityArray = {
  taggerowneraddress: string,
  taggeroutpoint: string,
  taggerfoxname: string,
  taggerimage: string,
  taggerimagelink: string,
  taggeeowneraddress: string,
  taggeeoutpoint: string,
  taggeefoxname: string,
  taggeeimage: string,
  taggeeimagelink: string,
  time: number,
  txid: string
}


export const PickAFox = () => {

  const [ordaddress, setOrdAddress] = useState<string>();
  const [addresses, setAddresses] = useState<Addresses | undefined>();
  const [myordaddress, setMyOrdAddress] = useState<string | undefined>(undefined);

  //show actions menu
  const [showactions, setShowActions] = useState<boolean>(false);

  //loading
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  //ordinalsstring for Foxes Child Component
  const [ordinalsstring, setOrdinalsString] = useState<string | undefined>();

  //filters var => search results
  const [faucetvar, setFaucetVar] = useState<boolean>(false);
  const [myvar, setMyVar] = useState<boolean>(true);
  const [demovar, setDemoVar] = useState<boolean>(false);
  const [pickvar, setPickVar] = useState<boolean>(true);

  //useRefs
  const didMount1 = useRef(false);
  //get bstate from search results
  const state = useLocation();

  //tag var
  const [tag, setTag] = useState<boolean>(false);

  //activity
  const [tempactivity, setTempActivity] = useState<ActivityArray>();
  const [txidlink, setTxidLink] = useState<string>();
  const [inscriptionlink, setInscriptionLink] = useState<string>();
  const [tagdate, setTagDate] = useState<string>();
  const [txid, setTxid] = useState<string>();

  React.useEffect(() => {
    //(state.state.owneraddress)
    setMyOrdAddress(state.state.owneraddress)
    setOrdAddress(state.state.owneraddress)
    setTag(state.state.tag)

    //if activity, load top section
    if (state.state.activity) {
      setTempActivity(state.state.activity)
      let txidlinktemp = "https://whatsonchain.com/tx/" + state.state.txid;
      let inscriptionlinktemp = "https://ordinals.gorillapool.io/content/" + state.state.txid + "_0";
    
      let ggg: number = state.state.date;
     // console.log(ggg)
      let hhh = new Date(ggg).toLocaleString();
     //  console.log(hhh)
      setTxidLink(txidlinktemp)
      setInscriptionLink(inscriptionlinktemp)
      setTagDate(hhh)
      setTxid(state.state.txid)
    }
    //temp
    // setOrdinalsString(JSON.stringify(demojson))
    // setLoaded(true)
    //end temp
  }, [])

  useEffect(() => {
    if (tag === true) {
      setPickVar(false)
      setMyVar(false)
    }
  }, [tag]);


  // get ordinals string
  useEffect(() => {
    if (!didMount1.current) {
      didMount1.current = true;
      return;
    }
    let result;
    if (ordaddress) {
      result = ordaddress.trim();
    }

    //fetch
    fetch("https://ordinals.gorillapool.io/api/txos/address/" + result + "/unspent?limit=100000", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
          setOrdinalsString(JSON.stringify(data))
          setLoaded(true)
      })
      .catch((error) => console.log(error));
  }, [ordaddress]);







  //go home 
  const goToIndex = async () => {
    window.location.href = "/";
  };

  //return jsx
  return (
    <div className="App">

      {!loaded && (
        <>
          <header className="App-header">
            <FoxplorerLogoHome onClick={goToIndex} />
            <div className="LoaderHeight">
              <div id="error"></div>
              <ThreeCircles color="black" height="50"
                width="50" />
            </div>
            <MyOtherFox /><MyFox />
          </header>
        </>
      )}

      {loaded && (

        <>
          <TemporaryDrawer />
          <div className="Topbar">
            <FoxplorerLogo onClick={goToIndex} />
            <a target="blank" href="https://twitter.com/yours_foxplorer"><XLogoRight /></a>
          </div>





          <div id="Faucet">
            {tempactivity &&

              <div className="TaggedInfo">
                <h3>You Got Tagged</h3>
                {/* <h3>{tempactivity.taggerfoxname} tagged {tempactivity.taggeefoxname}</h3> */}
                <br />
                <div className="TaggedPlayers">
                  <a target="blank"
                    href={tempactivity.taggerimagelink}>
                    <img className="ActivityIt" src={tempactivity.taggerimage}
                      id={tempactivity.taggeroutpoint} />
                  </a>
                  <a target="blank"
                    href={tempactivity.taggeeimagelink}>
                    <img className="ActivityNotIt" src={tempactivity.taggeeimage}
                      id={tempactivity.taggeeoutpoint} />
                  </a>
                </div>
                <br />
                <span>
                            <a className="White" target="blank"
                              href={tempactivity.taggerimagelink}>{tempactivity.taggerfoxname}</a> tagged <a className="White" target="blank"
                                href={tempactivity.taggeeimagelink}>{tempactivity.taggeefoxname}</a>
                                </span>
                                <br />
                                {tagdate}<br /><br />
                              <a className="White" target="blank" href={txidlink}>  <u>View transaction</u></a>
                              <a className="White" target="blank" href={inscriptionlink}>  <u>View inscription</u></a><br />
                              
                              <br /><br />
                <h3>Pick Another Fox</h3>

              </div>
            }
            {!tempactivity &&
              <p className="Heading"><span className="Demo"><b>Pick A Fox</b></span></p>
            }

          </div>
          <Filters ordinalsstring={ordinalsstring} myordinalsaddress={myordaddress} faucetvar={faucetvar} myvar={myvar} demovar={demovar} pickvar={pickvar} tag={tag} showactions={showactions} />
          <FooterHome />
          {/* <CreateGroupText groupordinalsstring={ordinalsstring} /> */}
          {/* <CreateJsonForAll groupordinalsstring={ordinalsstring} /> */}
        </>
      )}


    </div>
  );
};