import fire from "../assets/fire.png";

export type FireProps = {
  onClick: () => void;
};

// NOTE: Using inline styling demo but prefer styled-components or CSS classes in real app
export const Fire = (props: FireProps) => {
  const { onClick } = props;
  return (
      <img
      onClick={onClick}
        src={fire}
        alt="Fire"
        style={{
          cursor:"pointer",
          padding:"5px",
          position:"relative",
          height:"7vh"
        }}
      />
   
  
  );
};