import { useState, useEffect, useRef } from "react";
import { PandaConnectButton } from "../components/PandaConnectButton";
import Form from "../components/Form";
import { DemoButton } from "../components/DemoButton";
import { FoxplorerLogo } from "../components/FoxplorerLogo";
import { FoxplorerLogoHome } from "../components/FoxplorerLogoHome";
import { MyFox } from "../components/MyFox";
import { NormalFaucet } from "../components/NormalFaucet";
import { MyOtherFox } from "../components/MyOtherFox";
import { ThreeCircles } from 'react-loader-spinner';
import { XLogoRight } from "../components/XLogoRight";
import { XLogo } from "../components/XLogo";
import Filters from "../components/Filters";
import Footer from "../components/Footer";
import TemporaryDrawer from "../components/Drawer";
import FooterHome from "../components/FooterHome";
import CreateGroupText from '../components/CreateGroupText';
import CreateJsonForAll from '../components/CreateJsonForAll';


import {
  usePandaWallet,
  Addresses
} from "panda-wallet-provider";

import demojson from "../assets/demo3069.json";
import { Drawer } from "@mui/material";

export const Foxplorer = () => {

  //use yours wallet
  const wallet = usePandaWallet();
  const [pubKey, setPubKey] = useState<string | undefined>();
  const [ordaddress, setOrdAddress] = useState<string>();
  const [addresses, setAddresses] = useState<Addresses | undefined>();
  const [myordaddress, setMyOrdAddress] = useState<string | undefined>(undefined); 

  //show actions menu
  const [showactions, setShowActions] = useState<boolean>(false);

  //loading
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  //ordinalsstring for Foxes Child Component
  const [ordinalsstring, setOrdinalsString] = useState<string | undefined>();

    //filters var => search results
    const [faucetvar, setFaucetVar] = useState<boolean>(false);
    const [myvar, setMyVar] = useState<boolean>(true);
    const [demovar, setDemoVar] = useState<boolean>(false);
    const [pickvar, setPickVar] = useState<boolean>(false);
    const [tag, setTag] = useState<boolean>(false);

  //formdata
  interface FormData {
    name: string;
  }

  //useRefs
  const didMount1 = useRef(false);
  const didMount2 = useRef(false);

  // get ord address
      // get ord address
      useEffect(() => {
        if (!didMount2.current) {
          didMount2.current = true;
          return;
        }
        // console.log(addresses.ordAddress)
        setMyOrdAddress(addresses.ordAddress)
        //temp
        // setOrdinalsString(JSON.stringify(demojson))
        // setLoaded(true)
        setShowActions(true)
        setOrdAddress(addresses.ordAddress)
      }, [addresses]);
    // get ordinals string
    useEffect(() => {
      if (!didMount1.current) {
        didMount1.current = true;
        return;
      }
      let result;
      if(ordaddress){
        result = ordaddress.trim();
      }
      
      //fetch
      fetch("https://ordinals.gorillapool.io/api/txos/address/" + result + "/unspent?limit=100000", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          document.getElementById("error")!.innerHTML = "";
          if((data.message)){
            setLoading(false);
            document.getElementById("error")!.innerHTML = data.message + ": Please enter a valid Ordinals address";
          }else{
            // console.log(data);
          setOrdinalsString(JSON.stringify(data))
          setLoaded(true)
          }
        })
        .catch((error) => console.log(error));
    }, [ordaddress]);


  //handle ordinput
  const handleOrdInput = async (data: FormData) => {
    setLoading(true);
    let result = data.name.trim();
    setOrdAddress(result);
  };



    //handle demo connect
    const handleDemoConnect = async () => {
      window.location.href = "/demofoxes";
          //current working code
          // setOrdinalsString(JSON.stringify(demojson))
          // setLoaded(true)


      // setOrdAddress("1CFCMQb4uC9fvBCsmk1pfWjdwouAJ5BEJe")
      // setTimeout(() => {
      //   if (demojson) {
      //     setLoading(true)
      //     setOrdAddress("1CFCMQb4uC9fvBCsmk1pfWjdwouAJ5BEJe")
      //     // setOrdinalsString(JSON.stringify(demojson))
      //     // setLoaded(true)
      //   } else {
      //     handleDemoConnect();
      //   }
      // }, 2000);
    };

    //handle get adresses
    const handleGetAddresses = async () => {
      const addrs = await wallet.getAddresses();
      if (addrs) setAddresses(addrs);
    };

  //handle connect
  const handleConnect = async () => {
    setLoading(true);
    if (!wallet.connect) {
      window.open(
        "https://github.com/Panda-Wallet/panda-wallet#getting-started-alpha",
        "_blank"
      );
      setLoading(false);
      return;
    }

    const key = await wallet.connect();
    if (key) {
      setPubKey(key);
          setTimeout(() => {
            handleGetAddresses()
    }, 1000);

    }
    if (!key) {
      wallet.disconnect()
      alert("Make sure you are signed-in to Yours Wallet and try again.")
      setLoading(false);
      return
    }
  };

  //go home 
  const goToIndex = async () => {
    window.location.href = "/";
  };

  const goToFaucet = async () => {
    window.location.href = "/faucet";
  };

  //return jsx
  return (
    <div className="App">

      {!loaded && (
        <>
          <header className="App-header">
          <TemporaryDrawer />
            <a target="blank" href="https://twitter.com/yours_foxplorer"><XLogo /></a>
            <FoxplorerLogoHome onClick={goToIndex} />
            <PandaConnectButton onClick={handleConnect} />
            <DemoButton onClick={handleDemoConnect} />
            <div id="error"></div>
            <Form onSubmit={handleOrdInput} />
            <div className="LoaderHeight">
            {loading && (
              <>
                <ThreeCircles color="black" height="50"
                  width="50" />
              </>
            )}
            </div>
            <MyOtherFox /><MyFox /><NormalFaucet onClick={goToFaucet}/>
          </header>
          <FooterHome />

        </>
      )}


      {loaded && (
      
        <>
        <TemporaryDrawer />
          <div className="Topbar">
            <FoxplorerLogo onClick={goToIndex} />
            <a target="blank" href="https://twitter.com/yours_foxplorer"><XLogoRight /></a>
          </div>
          <div id="Faucet">
      <p className="Heading"><span className="Demo"><b>Foxchain Explorer</b></span><br />Sort Unlimited Pixel Foxes</p>

        </div>
          <Filters ordinalsstring={ordinalsstring} myordinalsaddress={myordaddress} faucetvar={faucetvar} myvar={myvar} demovar={demovar} pickvar={pickvar} tag={tag} showactions={showactions} />
          <FooterHome />
          {/* <CreateGroupText groupordinalsstring={ordinalsstring} /> */}
          {/* <CreateJsonForAll groupordinalsstring={ordinalsstring} /> */}
        </>
      )}


    </div>
  );
};