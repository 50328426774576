import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
// import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Button, Link, InputProps } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';


export default function TemporaryDrawer() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };



  const DrawerList = (
    <Box sx={{ width: 250 }}  role="presentation" onClick={toggleDrawer(false)}>
      <List>
        <a className="MenuLinks" href="/">
      <ListItem >
        <ListItemText primaryTypographyProps={{fontSize: '1.5rem', color: '#000000'}} primary="Home" />
   </ListItem>
   </a>
   <a className="MenuLinks" href="/live">
      <ListItem >
        <ListItemText primaryTypographyProps={{fontSize: '1.5rem', color: '#000000'}} primary="Live" />
   </ListItem>
   </a>
   <a className="MenuLinks" href="/faucet">
   <ListItem >
        <ListItemText primaryTypographyProps={{fontSize: '1.5rem', color: '#000000'}} primary="Faucet" />
   </ListItem>
   </a>
   {/* <a className="MenuLinks" href="/fogs">
   <ListItem >
        <ListItemText primaryTypographyProps={{fontSize: '1.5rem', color: '#000000'}} primary="Fogs" />
   </ListItem>
   </a> */}
   {/* <a className="MenuLinks" href="/tag">
   <ListItem >
        <ListItemText primaryTypographyProps={{fontSize: '1.5rem', color: '#000000'}} primary="Tag" />
   </ListItem>
   </a> */}
   <a className="MenuLinks" href="/demofoxes">
   <ListItem >
        <ListItemText primaryTypographyProps={{fontSize: '1.5rem', color: '#000000'}} primary="Demo Foxes" />
   </ListItem>
   </a>
   <a className="MenuLinks" href="/faucetfoxes">
   <ListItem >
        <ListItemText primaryTypographyProps={{fontSize: '1.5rem', color: '#000000'}} primary="Faucet Foxes" />
   </ListItem>
   </a>
   {/* <a className="MenuLinks" href="/groups">
   <ListItem >
        <ListItemText primaryTypographyProps={{fontSize: '1.5rem', color: '#000000'}} primary="Groups" />
   </ListItem>
   </a> */}


        {/* {[`<a href="/faucet">Home</a>`, 'Demo', 'Groups', 'Faucet'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>

              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem> 
        ))} */}
      </List>
    </Box>
  );

  return (
    <div className="Hamburger">
      <Button onClick={toggleDrawer(true)}><MenuIcon className="svg_icons"/></Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
