import up from "../assets/up.png";

export type UpProps = {
  onClick: () => void;
};

// NOTE: Using inline styling demo but prefer styled-components or CSS classes in real app
export const Up = (props: UpProps) => {
  const { onClick } = props;
  return (
      <img
      onClick={onClick}
        src={up}
        alt="Up"
        style={{
          cursor:"pointer",
          padding:"5px",
          position:"relative",
          height:"7vh"
        }}
      />
   
  
  );
};