import { useState, useEffect, useRef } from "react";
import { PandaConnectButton } from "../components/PandaConnectButton";
import Form from "../components/Form";
import { DemoButton } from "../components/DemoButton";
import { FoxplorerLogo } from "../components/FoxplorerLogo";
import { FoxplorerLogoHome } from "../components/FoxplorerLogoHome";
import { MyFox } from "../components/MyFox";
import { MyOtherFox } from "../components/MyOtherFox";
import { ThreeCircles } from 'react-loader-spinner';
import { XLogoRight } from "../components/XLogoRight";
import { XLogo } from "../components/XLogo";
import Filters from "../components/Filters";
import Footer from "../components/Footer";
import FooterHome from "../components/FooterHome";
import CreateGroupText from '../components/CreateGroupText';
import CreateJsonForAll from '../components/CreateJsonForAll';

import {
  usePandaWallet,
  Addresses
} from "panda-wallet-provider";
import TemporaryDrawer from "../components/Drawer";
import demojson from "../assets/demo3069.json";

export const DemoFoxes = () => {

  //use yours wallet
  const wallet = usePandaWallet();
  const [pubKey, setPubKey] = useState<string | undefined>();
  const [ordaddress, setOrdAddress] = useState<string>();
  const [addresses, setAddresses] = useState<Addresses | undefined>();
  const [myordaddress, setMyOrdAddress] = useState<string | undefined>(undefined); 

    //show actions menu
    const [showactions, setShowActions] = useState<boolean>(false);


  //loading
  const [loading, setLoading] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(false);

  //ordinalsstring for Foxes Child Component
  const [ordinalsstring, setOrdinalsString] = useState<string | undefined>(JSON.stringify(demojson));

    //filters var => search results
    const [faucetvar, setFaucetVar] = useState<boolean>(false);
    const [myvar, setMyVar] = useState<boolean>(false);
    const [demovar, setDemoVar] = useState<boolean>(true);
    const [pickvar, setPickVar] = useState<boolean>(false);
    const [tag, setTag] = useState<boolean>(false);
  //useRefs
  const didMount = useRef(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
}, 3000);
  }, [])



  //go home 
  const goToIndex = async () => {
    window.location.href = "https://foxplorer.org/";
  };

  //return jsx
  return (
    <div className="App">
            {!loaded && (
        <>
                  <header className="App-header">
                  <FoxplorerLogoHome onClick={goToIndex} />
            <div className="LoaderHeight">

                <ThreeCircles color="black" height="50"
                  width="50" />
            </div>
            <MyOtherFox /><MyFox />
            </header>
        </>
      )}
      {loaded && (
        <>
        <TemporaryDrawer />
          <div className="Topbar">
            <FoxplorerLogo onClick={goToIndex} />
            <a target="blank" href="https://twitter.com/yours_foxplorer"><XLogoRight /></a>
          </div>
          <div id="Faucet">
      <p className="Heading"><span className="Demo"><b>Demo Foxes Group</b></span><br />Sort 3,069 Demo Foxes</p>
        </div>
          <Filters showactions={showactions} ordinalsstring={ordinalsstring} myordinalsaddress={myordaddress} faucetvar={faucetvar} myvar={myvar} demovar={demovar} tag={tag} pickvar={pickvar} />
          <FooterHome />
          {/* <CreateGroupText groupordinalsstring={ordinalsstring} /> */}
          {/* <CreateJsonForAll groupordinalsstring={ordinalsstring} /> */}
        </>
      )}
    </div>
  );
};