import React, { useState, useEffect, useRef } from "react";
import { FaucetPandaConnectButton } from "../components/FaucetPandaConnectButton";
import { FoxplorerLogo } from "../components/FoxplorerLogo";
import { FoxplorerLogoHome } from "../components/FoxplorerLogoHome";
import { MyFox } from "../components/MyFox";
import { Faucet } from "../components/Faucet";
import { ActualFaucet } from "../components/ActualFaucet";
import { MyOtherFox } from "../components/MyOtherFox";
import { ThreeCircles } from 'react-loader-spinner';
import { XLogoRight } from "../components/XLogoRight";
import { XLogo } from "../components/XLogo";
import FaucetFilters from "../components/FaucetFilters";
import FooterHome from "../components/FooterHome";
import { DateRange, MiniChart } from "react-ts-tradingview-widgets";
import CreateGroupText from '../components/CreateGroupText';
import EmojiPicker from 'emoji-picker-react';
import { SuggestionMode } from 'emoji-picker-react';
import { AiOutlineEye } from "react-icons/ai";
import {
  usePandaWallet,
  Addresses
} from "panda-wallet-provider";
import faucet2000 from "../assets/faucetFoxes428.json";
import demojson from "../assets/demo3069.json";
import { fabClasses } from "@mui/material";
import TemporaryDrawer from "../components/Drawer";

import { Fox } from "../components/Fox";
import { WeLive } from "../components/Live";
import { Fire } from "../components/Fire";
import { Heart } from "../components/Heart";
import { Up } from "../components/Up";
import { Rocket } from "../components/Rocket";
import { Down } from "../components/Down";
//socketio
import { io } from 'socket.io-client';
import type { Socket } from 'socket.io-client';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

var foximg = require('../assets/fox.png')

type LiveWithFoxProps = {

}

export const  LiveWithFox = ({ }: LiveWithFoxProps) => {
  //chart
  const [trans, setTrans] = useState<boolean>(true);
  //loading for twins
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(true);
  const [preloaded, setPreLoaded] = useState<boolean>(true);
  //use yours
  const wallet = usePandaWallet();
  const [pubKey, setPubKey] = useState<string | undefined>();
  const [addresses, setAddresses] = useState<Addresses | undefined>();
  const [myordaddress, setMyOrdAddress] = useState<string>("");
  const [ordaddress, setOrdAddress] = useState<string>();

  //ordinalsstring for Foxes Child Component
  const [ordinalsstring, setOrdinalsString] = useState<string | undefined>();
  //owns a fox
  const [ownsfox, setOwnsFox] = useState<boolean>(false);
  const [foxesowned, setFoxesOwned] = useState<number | string>("?");
  const [foxname, setFoxName] = useState<string>();
  const [foximagesrc, setFoxImageSrc] = useState<string>();
  const [foximageurl, setFoxImageUrl] = useState<string>();
  const [foximageinfo, setFoxImageInfo] = useState<string>();

  //chart
  const [daterange, setDateRange] = useState<DateRange>("ALL");
  const [ticker, setTicker] = useState<string>("BSVUSD");
  const [charturl, setChartUrl] = useState<string>("https://www.tradingview.com/symbols/BSVUSD/");
  //useRefs
  const didMount = useRef(false);
  const didMount1 = useRef(false);
  const didMount2 = useRef(false);
  const didMount3 = useRef(false);
  const didMount4 = useRef(false);
  useEffect(() => {
    setTimeout(() => {
      setPreLoaded(false)

    }, 3000);
  }, [])
  const [viewers, setViewers] = useState<number | string>('')
  // const [message, setMessage] = useState<string>("awaiting reactions")
  // const [time, setTime] = React.useState('fetching')
  const [sock, setSock] = useState<Socket>();

//get bstate from search results
const state = useLocation();

  React.useEffect(() => {
    // console.log(state.state.foxes)
    // console.log(state.state.owneraddress)
    // console.log(state.state.originoutpoint)
    // console.log(state.state.foxname)

    setMyOrdAddress(state.state.owneraddress)
    setFoxesOwned(state.state.foxes)
    setFoxName(state.state.foxname)
    setFoxImageSrc(state.state.originoutpoint)
    setFoxImageUrl("https://ordfs.network/content/" + state.state.originoutpoint)
    setFoxImageInfo("https://alpha.1satordinals.com/outpoint/" + state.state.originoutpoint + "/inscription")
  
    if(state.state.foxes > 0){
      setOwnsFox(true)
    }
    // const socket = io('http://localhost:5000')
    const socket = io('https://foxlive-7ab7fefab994.herokuapp.com/')
    setSock(socket)
    // socket.on('connect', () => console.log(socket.id))
    socket.on('connect_error', () => {
      setTimeout(() => socket.connect(), 1000)
    })
    // socket.on('time', (data) => setTime(data))
    socket.on('message', (data) => getMessage(data))
    socket.on('viewers', (data) => setViewers(data))

  }, [])

  //go home 
  const goToIndex = async () => {
    window.location.href = "https://foxplorer.org/";
  };

  //go home 
  const dateRange = (str: string) => {

    let all: DateRange = "ALL";
    let oneday: DateRange = "1D";
    let onemonth: DateRange = "1M";
    let threemonth: DateRange = "3M";
    let twelvemonth: DateRange = "12M";
    let sixtymonth: DateRange = "60M";

    if (str === "ALL") {
      setDateRange(all)
    } else if (str === "1D") {
      setDateRange(oneday)
    } else if (str === "1M") {
      setDateRange(onemonth)
    } else if (str === "3M") {
      setDateRange(threemonth)
    } else if (str === "12M") {
      setDateRange(twelvemonth)
    } else if (str === "60M") {
      setDateRange(sixtymonth)
    }

  };

  // get ord address
  useEffect(() => {
    if (!didMount2.current) {
      didMount2.current = true;
      return;
    }
    // console.log(addresses.ordAddress)
    setMyOrdAddress(addresses.ordAddress)
    setOrdAddress(addresses.ordAddress)
    // sendUtxos()
    // getUtxos()
  }, [addresses]);


  const container = document.getElementById('circlesid');
  //launch fox
  const launchFox = (fox: string, src: string) => {
    let div = document.getElementById("circles");
    let li = document.createElement("li");
    li.innerHTML = `
    <li><img src='fox.png' /><br />` + fox + `</li>
    `
    div.append(li);

    // let div = document.getElementById("circles");
    // let li = document.createElement("li");
    // let foximg = document.createElement("img");
    // let emoji = document.createElement("img");
    // foximg.src = "https://ordfs.network/content/" + src;
    // foximg.width = 64;
    // emoji.src = "fox.png";
    // li.append(foximg)
    // li.append(emoji)
  
    // div.append(li);
    //     document.getElementById("circles").innerHTML += 
    //     `
    // <li><img src='fox.png' /></li>
    // `

  };

  //launch fire
  const launchFire = (fox: string) => {
    let div = document.getElementById("circles");
    let li = document.createElement("li");
    li.innerHTML = `
    <li><img src='fire.png' /><br />` + fox + `</li>
    `
    div.append(li);

    // var ul = document.getElementById("circles");
    // var li = document.createElement("li");
    // li.innerHTML = "fireeeeeeeeeeeeeee";

    // ul.appendChild(li);



  };
  //launch heart
  const launchHeart = (fox: string) => {
    let div = document.getElementById("circles");
    let li = document.createElement("li");
    li.innerHTML = `
    <li><img src='heart.png' /><br />` + fox + `</li>
    `
    div.append(li);

  };
  //launch up
  const launchUp = (fox: string) => {
    let div = document.getElementById("circles");
    let li = document.createElement("li");
    li.innerHTML = `
    <li><img src='up.png' /><br />` + fox + `</li>
    `
    div.append(li);

  };
  //launch rocket
  const launchRocket = (fox: string) => {
    let div = document.getElementById("circles");
    let li = document.createElement("li");
    li.innerHTML = `
    <li><img src='rocket.png' /><br />` + fox + `</li>
    `
    div.append(li);

  };

  const getMessage = (data) => {
    if (data.reaction === "fox") {
      launchFox(data.fox, data.src);
    } else if (data.reaction === "fire") {
      launchFire(data.fox);
    } else if (data.reaction === "heart") {
      launchHeart(data.fox);
    } else if (data.reaction === "up") {
      launchUp(data.fox);
    } else if (data.reaction === "rocket") {
      launchRocket(data.fox);
    }
  };

  // get ord address
  useEffect(() => {
    if (!didMount3.current) {
      didMount3.current = true;
      return;
    }
    setTimeout(() => {
      setLoading(false)
      setLoaded(true)
    }, 2000);
  }, [myordaddress]);

  // get ordinals string
  useEffect(() => {
    if (!didMount1.current) {
      didMount1.current = true;
      return;
    }
    let result;
    if (ordaddress) {
      result = ordaddress.trim();
    }

    //fetch
    fetch("https://ordinals.gorillapool.io/api/txos/address/" + result + "/unspent?limit=100000", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        document.getElementById("Error")!.innerHTML = "";
        if ((data.message)) {
          document.getElementById("Error")!.innerHTML = data.message + ": Please refresh and try again";
        } else {
          // console.log(data);
          setOrdinalsString(JSON.stringify(data))
        }
      })
      .catch((error) => console.log(error));
  }, [ordaddress]);

  // get ordinals string
  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    //get num foxes
    let tempstring;
    tempstring = JSON.parse(ordinalsstring);
    let foxlength = tempstring.length;
    //push to new array instead of splicing
    let newstring = [];


    //purge non foxes, also make sure all traits exist, need indexer, need to query 1sat-server for foxes only
    // and look into optional chaining...why tempstring[i]? below and not the others?
    for (let i = 0; i < foxlength; i++) {
      if (tempstring[i].origin?.data?.map?.subTypeData?.collectionId === "1611d956f397caa80b56bc148b4bce87b54f39b234aeca4668b4d5a7785eb9fa_0") {
        newstring.push(tempstring[i])
      } else if (tempstring[i].data?.inscription?.file?.hash) {
        // console.log(tempstring[i].data?.inscription?.file?.hash + " = has a group")
      }
    }
    foxlength = newstring.length;
    // console.log(newstring.length)
    setFoxesOwned(newstring.length);
    if (newstring.length > 0) {
      setOwnsFox(true)
    }

  }, [ordinalsstring]);


  //handle get adresses
  const handleGetAddresses = async () => {
    const addrs = await wallet.getAddresses();
    if (addrs) setAddresses(addrs);
  };

  //handle connect
  const handleConnect = async () => {
    setLoading(true);
    setMyOrdAddress("")
    if (!wallet.connect) {
      window.open(
        "https://github.com/Panda-Wallet/panda-wallet#getting-started-alpha",
        "_blank"
      );
      setLoading(false);
      return;
    }

    const key = await wallet.connect();
    if (key) {
      setPubKey(key);
      setTimeout(() => {
        handleGetAddresses()
      }, 1000);

    }
    if (!key) {
      wallet.disconnect()
      alert("Make sure you are signed-in to Yours Wallet and try again.")
      setLoading(false);
      return
    }
  };

  const submitFox = () => {

    if (myordaddress === "") {
      alert('You must connect Yours Wallet and own a fox to react')
      return
    }
    if (myordaddress !== "" && ownsfox === false) {

      alert('You must own a fox to react')
      return
    }
    if (myordaddress !== "" && ownsfox === true) {
      // launchFox(foxname)
      sock.emit('message', { reaction: 'fox', fox: {foxname}, src: {foximagesrc}})
    }

  };

  const submitFire = () => {

    if (myordaddress === "") {
      alert('You must connect Yours Wallet and own a fox to react')
      return
    }
    if (myordaddress !== "" && ownsfox === false) {

      alert('You must own a fox to react')
      return
    }
    if (myordaddress !== "" && ownsfox === true) {
      sock.emit('message', { reaction: 'fire', fox: {foxname}, src: {foximagesrc} })
    }

  };

  const submitHeart = () => {

    if (myordaddress === "") {
      alert('You must connect Yours Wallet and own a fox to react')
      return
    }
    if (myordaddress !== "" && ownsfox === false) {

      alert('You must own a fox to react')
      return
    }
    if (myordaddress !== "" && ownsfox === true) {
      sock.emit('message', { reaction: 'heart', fox: {foxname}, src: {foximagesrc} })
    }

  };

  const submitUp = () => {

    if (myordaddress === "") {
      alert('You must connect Yours Wallet and own a fox to react')
      return
    }
    if (myordaddress !== "" && ownsfox === false) {

      alert('You must own a fox to react')
      return
    }
    if (myordaddress !== "" && ownsfox === true) {
      sock.emit('message', { reaction: 'up', fox: {foxname}, src: {foximagesrc} })
    }

  };
  const submitRocket = () => {

    if (myordaddress === "") {
      alert('You must connect Yours Wallet and own a fox to react')
      return
    }
    if (myordaddress !== "" && ownsfox === false) {

      alert('You must own a fox to react')
      return
    }
    if (myordaddress !== "" && ownsfox === true) {
      sock.emit('message', { reaction: 'rocket', fox: {foxname}, src: {foximagesrc} })
    }
  };



  return (

    <div className="App">
      <div id="Error"></div>

      {preloaded && (
        <>
          <header className="App-header">

            <FoxplorerLogoHome onClick={goToIndex} />
            <div className="LoaderHeight">

              <ThreeCircles color="black" height="50"
                width="50" />
            </div>
            <MyOtherFox /> <MyFox />
          </header>
        </>
      )}
      {!preloaded && (
        <>

          <TemporaryDrawer />
          <div className="Topbar">
            <FoxplorerLogo onClick={goToIndex} />
            <a target="blank" href="https://twitter.com/yours_foxplorer"><XLogoRight /></a>
          </div>

          <div id="Live">
            <br />


            {/* {!loaded && (
              <>
                {!loading && (
                  <>

                    <div className="Yours">
                      <FaucetPandaConnectButton onClick={handleConnect} />
                    </div>

                  </>

                )}

                {loading && (
                  <>
                    <span className="Required">
                      <ThreeCircles color="white" height="50"
                        width="50" />
                    </span>
                  </>
                )}
              </>
            )} */}

            {loaded && (
              <>
              <div className="Status">
              <span className="StatusLeft">
         
         <span className="Demo"><b>Connected</b></span>
         <br />

                      <br />
                      <a target="blank" href={foximageinfo}>
                      <img 
      src={foximageurl}
      className="YourFox"
      alt="Your Fox"
      />
      </a>
      <br />
         {foxname}<br />
         Address: {state.state.owneraddress}
         <br />
         Foxes: {state.state.foxes}<br /><br />
       </span>
       <span className="StatusRight">
         
       <WeLive />
       <div className="ViewersContainer">
            <div className="Viewers">
<AiOutlineEye size={33} /><span>  {viewers}</span>
</div></div>
       </span>


              </div>

              </>
            )}

<br /><br />
            <ul className="FilterList">
              <li>
              <label className="Label">Ticker</label>
              <select id="daterange" onChange={(e) => {
                setTicker(e.target.value);
                setChartUrl("https://www.tradingview.com/symbols/" + e.target.value + "/")
              }}>
                <option key="BSVUSD" value="BSVUSD">BSV</option>
                <option key="BTCUSD" value="BTCUSD">BTC</option>
                <option key="BCHUSD" value="BCHUSD">BCH</option>
                <option key="SOLUSD" value="SOLUSD">SOL</option>
              </select>
            </li>
            <li>
           
              <label className="Label">Chart</label>
              <select id="timeframe" onChange={(e) => {
                dateRange(e.target.value);
              }}>
                <option key="ALL" value="ALL">All</option>
                <option key="1D" value="1D">1D</option>
                <option key="1M" value="1M">1M</option>
                <option key="3M" value="3M">3M</option>
                <option key="12M" value="12M">12M</option>
                <option key="60M" value="60M">60M</option>
              </select>
              </li>
              </ul>
            <MiniChart underLineBottomColor="rgb(54,191,250)" dateRange={daterange} largeChartUrl={charturl} trendLineColor="rgb(54,191,250)" isTransparent={trans} colorTheme="dark" width="100%" symbol={ticker}></MiniChart>

            <p className="React">
            {/* <span className="Emojis"> */}
              <Fox onClick={submitFox} /><Fire onClick={submitFire} /><Heart onClick={submitHeart} /><Up onClick={submitUp} /><Rocket onClick={submitRocket} />
            {/* </span> */}
            </p>

            <br /><br /><br />
            <ul id='circles'>
            </ul>
          </div>
          {/* <EmojiPicker /> */}
          <FooterHome />

        </>
      )}
    </div>


  )
};