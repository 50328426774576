import "./App.css";
import { Foxplorer } from "./page/Foxplorer";
import { Live } from "./page/Live";
import { Tag } from "./page/Tag";
import { PrivacyPolicyAndTermsOfService } from "./page/PrivacyPolicyAndTermsOfService";
import { FoxFaucet } from "./page/FoxFaucet";
import { DemoFoxes } from "./page/DemoFoxes";
import { FaucetFoxes } from "./page/FaucetFoxes";
import { CommunityFaucet } from "./page/CommunityFaucet";
import { LiveWithFox } from "./page/LiveWithFox";
import { TagWithFox } from "./page/TagWithFox";
import { TestFoxFaucet } from "./page/TestFoxFaucet";
import FoxGroups from "./page/FoxGroups";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { PickAFox } from "./page/PickAFox";


export const App = () => {

  return (

    <>
      {/* This is the alias of BrowserRouter i.e. Router */}
      <Router>
        <Routes>
          {/* This route is for home component 
  with exact path "/", in component props 
  we passes the imported component*/}
          <Route
            path="/"
            element={<Foxplorer />}
          />

          {/* This route is for about component 
  with exact path "/privacy-policy-and-terms-of-service", in component 
  props we passes the imported component*/}
          <Route
            path="/privacy-policy-and-terms-of-service"
            element={<PrivacyPolicyAndTermsOfService />}
          />

          <Route
            path="/faucet"
            element={<CommunityFaucet />}
          />

          <Route
            path="/live"
            element={<Live />}
          />

{/* <Route
            path="/fogs"
            element={<Fogs />}
          /> */}

{/* <Route
            path="/tagsupersecret"
            element={<Tag />}
          /> */}

          <Route
            path="/pickafox"
            element={<PickAFox />}
          />      

          <Route
            path="/livewithfox"
            element={<LiveWithFox />}
          />    
                    {/* <Route
            path="/tagwithfoxsupersecret"
            element={<TagWithFox />}
          />    
           */}
          {/* <Route
            path="/groups"
            element={<FoxGroups />}
          /> */}

          <Route
            path="/demofoxes"
            element={<DemoFoxes />}
          />
                    <Route
            path="/faucetfoxes"
            element={<FaucetFoxes />}
          />

        </Routes>
      </Router>
    </>
  );
}