import { useState, useEffect, useRef } from "react";
import { ShowMoreButton } from "../components/ShowMoreButton";
import { GetAnotherButton } from "../components/GetAnotherButton";
import { TestFox } from "../components/TestFox";
import TwinFinderFaucet from "./TwinFinderFaucet";
import { PulseLoader } from 'react-spinners';
import { Button } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import faucetgroup from "../assets/faucetfoxesgroup.json";

type CommunityFaucetSearchResultsProps = {
  faucetvar: boolean,
  myordinalsaddress: string,
  todisplay: string,
  background: string,
  name: string,
  body: string,
  mouth: string,
  head: string,
  eyes: string,
  item: string,
  totalresults: number,
  clearFilters: () => void,
  passedFunctionFromFilters: () => void,
  passedFunctionFoxLoadingScreen: (boolean) => void,
  setsearchloading: boolean,
  complete: boolean
}

type ToDisplay = {
  title: string;
  name: string;
  link: string;
  img: string;
  imgid: string;
  outpoint: string;
  owner: string;
  ownertrimmed: string;
  ownerlink: string;
  trait1: string;
  trait2: string;
  trait3: string;
  trait4: string;
  trait5: string;
  trait6: string;
  trait7: string;
}

const CommunityFaucetSearchResults = ({ complete, setsearchloading, passedFunctionFromFilters, passedFunctionFoxLoadingScreen, clearFilters, faucetvar, myordinalsaddress, todisplay, background, name, body, mouth, head, eyes, item, totalresults }: CommunityFaucetSearchResultsProps) => {

  //loading for results
  const [loading, setLoading] = useState<boolean>(true);

  //pass loading function to child
  const passedFunction = () => {
    setLoading(false);
    passedFunctionFromFilters();
  }


  //get fox screen
  const [showgetfoxscreen, setShowGetFoxScreen] = useState<boolean>(false);

  //display show more
  const [displayshowmore, setDisplayShowMore] = useState<boolean>(true);

  //set get fox buttons
  const [getfoxbuttons, setGetFoxButtons] = useState<string>("");
  const [available, setAvailable] = useState<string[]>([]);
  const [justtaken, setJustTaken] = useState<string[]>([]);
  const [foxesremaining, setFoxesRemaining] = useState<number>();
  //get fox screen
  const [foxloading, setFoxLoading] = useState<boolean>(false);
  const [getanother, setGetAnother] = useState<boolean>(false);
  //fox container
  const container = document.getElementById('error-container');

  //send fox server response
  const [txidurl, setTxidUrl] = useState<string>("");
  const [statusword, setStatusWord] = useState<string>("");
  const [foxserverresponse, setFoxServerResponse] = useState<string>("");
  const [errormessage, setErrorMessage] = useState<string>("");
  const [trait1, setTrait1] = useState<string>("");
  const [trait2, setTrait2] = useState<string>("");
  const [trait3, setTrait3] = useState<string>("");
  const [trait4, setTrait4] = useState<string>("");
  const [trait5, setTrait5] = useState<string>("");
  const [trait6, setTrait6] = useState<string>("");
  const [trait7, setTrait7] = useState<string>("");
  const [owner, setOwner] = useState<string>("");
  const [ownertrimmed, setOwnerTrimmed] = useState<string>("");
  const [ownerlink, setOwnerLink] = useState<string>("");
  const [getfoxlink, setGetFoxLink] = useState<string>("");
  const [getfoximg, setGetFoxImg] = useState<string>("");
  const [getfoxtitle, setFoxTitle] = useState<string>("");
  const [getfoxname, setGetFoxName] = useState<string>("");
  const [getfoximgid, setGetFoxImgId] = useState<string>("");

  //setnumresults
  const [foxresults, setFoxResults] = useState<number>(100);
  const [numresults, setNumResults] = useState<number | string>("?");

  // foxes to map
  const [displayfaucetfoxes, setDisplayFaucetFoxes] = useState<ToDisplay[]>([]);

  const addElement = (newElement) => {
    let c = [...displayfaucetfoxes, ...newElement];
    setDisplayFaucetFoxes(c);
  };

  //showtwins
  const [showtwins, setShowTwins] = useState<boolean>(false);

  //useRefs
  const didMount = useRef(false);
  const didMount1 = useRef(false);
  //resetLoading
  const resetLoading = () => {
    clearFilters()
  };

  //get fox
  const getFox = (e) => {
    if (myordinalsaddress === "") {
      alert("you must connect yours wallet extension to use the faucet")
      return
  } else {
      const id = e.currentTarget.getAttribute("data-title")
      const image = e.currentTarget.getAttribute("data-img")
      const originoutpoint = e.currentTarget.getAttribute("data-imgid")
      const outpoint = e.currentTarget.getAttribute("data-outpoint")
      // console.log(id)
      // console.log(myordinalsaddress)

      //set vars for fox image and details
      setFoxTitle(e.currentTarget.getAttribute("data-title"))
      setTrait1(e.currentTarget.getAttribute("data-trait1"))
      setTrait2(e.currentTarget.getAttribute("data-trait2"))
      setTrait3(e.currentTarget.getAttribute("data-trait3"))
      setTrait4(e.currentTarget.getAttribute("data-trait4"))
      setTrait5(e.currentTarget.getAttribute("data-trait5"))
      setTrait6(e.currentTarget.getAttribute("data-trait6"))
      setTrait7(e.currentTarget.getAttribute("data-trait7"))
      setOwner(e.currentTarget.getAttribute("data-owner"))
      setOwnerLink(e.currentTarget.getAttribute("data-owner-link"))
      setOwnerTrimmed(e.currentTarget.getAttribute("data-owner-trimmed"))
      setGetFoxLink(e.currentTarget.getAttribute("data-link"))
      setGetFoxImg(e.currentTarget.getAttribute("data-img"))
      setGetFoxName(e.currentTarget.getAttribute("data-name"))
      setGetFoxImgId(e.currentTarget.getAttribute("data-imgid"))

      setFoxLoading(true)
      setFoxServerResponse("")
      getScript(myordinalsaddress, id, outpoint)
      //update buttons if taken
      let temp = justtaken;
      temp.push(originoutpoint)
      setJustTaken(temp)
      setShowGetFoxScreen(true)
    }
  };

  // get inscriptions by faucet address to apply to getfox buttons
  useEffect(() => {

    if (!didMount1.current) {
      didMount1.current = true;
      return;
    }
    passedFunctionFoxLoadingScreen(showgetfoxscreen);
  }, [showgetfoxscreen]);

  //get fox for fox screen
  const setResponseData = (data: string) => {
    setFoxLoading(false)
    let hhh = JSON.parse(data);
    const result1 = String(data).includes('message');
    // console.log(result1)
    if (hhh.message != "broadcast successful") {
      setErrorMessage("Someone already got this fox. Refresh your browser to update available foxes.")
      // setStatusWord("Error")
    } else {
      //add outside scope fox error as else if
      //success
      let uuu = hhh.txid;
      setTxidUrl("https://whatsonchain.com/tx/" + uuu)
      setFoxServerResponse(uuu)
      setStatusWord("Fox Sent")
    }
  };

  //fox taken alert
  const taken = () => {
    if ((complete === false) && (myordinalsaddress === "")) {
      alert("you must connect yours wallet and the faucet hasn't opened yet and that Faucet Fox is already taken")
      return
    } else if (myordinalsaddress === "") {
      alert("you must connect yours wallet extension to use the faucet and that Faucet Fox is already taken")
      return
    } else {
      alert("Faucet Fox already taken. Try another fox.")
    }
  };

  // get ord address
  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    let buttonids = [];
    let d = 0;
    let remaining = 0;
    let dat = JSON.parse(getfoxbuttons)
    for (let i = 0; i < dat.length; i++) {
      buttonids[d] = dat[i].origin.outpoint;
      if (dat[i].origin?.data?.map?.subTypeData?.collectionId === "1611d956f397caa80b56bc148b4bce87b54f39b234aeca4668b4d5a7785eb9fa_0") {
        remaining++;
      }
      d++;
    }
    // console.log(buttonids)
    setAvailable(buttonids)
    setFoxesRemaining(remaining)

  }, [getfoxbuttons]);


  const backToResults = () => {
    setShowGetFoxScreen(false)
    setGetAnother(false)
    setStatusWord("")
    setErrorMessage("")
    setTxidUrl("")
    setFoxServerResponse("")
  };


  const getScript = async (myordinalsaddress: string, id: string, outpoint: string) => {
    //temp
    sendVarsToServer(myordinalsaddress, id, outpoint)
    // sendVarsToServer(myordinalsaddress, id, outpoint, data.script)
    //fetch
    //https://ordinals.gorillapool.io/api/txos/4b270882720d956b59364d581e43b36d854064760d6920e2d3e278f4f9cc546f_27?script=true
    // fetch("https://ordinals.gorillapool.io/api/txos/" + outpoint + "?script=true", {
    //   method: "GET",
    // })
    //   .then((response) => response.json())
    //   .then((data) => {

    //     // console.log(data + " = data")
    //     // set get fox buttons
    //     sendVarsToServer(myordinalsaddress, id, outpoint, data.script)
    //   }
    //   )
    //   .catch((error) => console.log(error));

  }

  //back to search results
  //sendvarstoserver
  //send addresses and avatar to server
  const sendVarsToServer = async (myordinalsaddress: string, id: string, outpoint: string) => {

    // console.log(script)
    const url = "https://tenmillionfoxes-fa782cb7a5d5.herokuapp.com/getfaucetfox";
    // const url = "http://localhost:9000/getfaucetfox";   
    // console.log(myordinalsaddress + "=addresses in sendvars")
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({ "id": id, "address": myordinalsaddress, "outpoint": outpoint })
      // body: JSON.stringify({ addresses, "avatar": avatar }),
    };
    fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setGetAnother(true)
        setResponseData(JSON.stringify(data))
      });
  };

  //handle demo connect
  const showMore = () => {
    let ggg = faucetgroup;
    let rrr = todisplay;
    if (rrr) {
      let ppp = JSON.parse(rrr)!;
      let foxcount = (foxresults - 50);
      let d = 0;
      let displayfaucetfoxestemp = [];

      //show up to 50 more
      for (let i = (foxresults - 50); i < foxresults; i++) {
        if (ppp[i] !== undefined) if (ppp[i].origin.data.map.subTypeData.collectionId === "1611d956f397caa80b56bc148b4bce87b54f39b234aeca4668b4d5a7785eb9fa_0") {
          // fox
          displayfaucetfoxestemp[d] = { img: "", name: "" }
          displayfaucetfoxestemp[d].img = "https://ordfs.network/content/" + ppp[i].origin.outpoint;
          displayfaucetfoxestemp[d].name = ppp[i].origin.data.map.name;
          displayfaucetfoxestemp[d].link = "https://alpha.1satordinals.com/outpoint/" + ppp[i].origin.outpoint + "/inscription";
          displayfaucetfoxestemp[d].imgid = ppp[i].origin.outpoint;
          displayfaucetfoxestemp[d].outpoint = ppp[i].outpoint;
          displayfaucetfoxestemp[d].trait1 = ppp[i].origin.data.map.subTypeData.traits[0].value;
          displayfaucetfoxestemp[d].trait2 = ppp[i].origin.data.map.subTypeData.traits[1].value;
          displayfaucetfoxestemp[d].trait3 = ppp[i].origin.data.map.subTypeData.traits[2].value;
          displayfaucetfoxestemp[d].trait4 = ppp[i].origin.data.map.subTypeData.traits[3].value;
          displayfaucetfoxestemp[d].trait5 = ppp[i].origin.data.map.subTypeData.traits[4].value;
          displayfaucetfoxestemp[d].trait6 = ppp[i].origin.data.map.subTypeData.traits[5].value;
          displayfaucetfoxestemp[d].trait7 = ppp[i].origin.data.map.subTypeData.traits[6].value;
          displayfaucetfoxestemp[d].owner = ppp[i].owner;
          let own = ppp[i].owner;
          displayfaucetfoxestemp[d].ownerlink = "https://whatsonchain.com/address/" + own;
          displayfaucetfoxestemp[d].ownertrimmed = own.substring(0, 10) + "...";

          //get group member title by image id in foxgroup 
          for (let k = 0; k < ggg.group.length; k++) {
            if (ggg.group[k]["origin.outpoint"] === ppp[i].origin.outpoint) {
              displayfaucetfoxestemp[d].title = ggg.group[k].foxData.foxName;
              // console.log(displayfaucetfoxestemp[d].title )
            }
          }
          d++;
          foxcount++;
        }
      }
      addElement(displayfaucetfoxestemp)

      //hide if no more foxes or add more foxes
      if (foxcount - foxresults < 0) {
        // console.log(foxcount - foxresults)
        setDisplayShowMore(false)
      } else {
        setFoxResults(foxresults + 50)
      }
    }
  };


  // get inscriptions by faucet address to apply to getfox buttons
  useEffect(() => {
    //run immediately and on every page load
    //faucet address
    let result = "1MicPBPE36mafsMPQXqgcF9Bt1NpwHHnvd";
    //fetch
    fetch("https://ordinals.gorillapool.io/api/txos/address/" + result + "/unspent?limit=100000", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {

        // console.log(data)
        // set get fox buttons
        // console.log(data)
        setGetFoxButtons(JSON.stringify(data))
      }
      )
      .catch((error) => console.log(error));


  }, []);

  useEffect(
    () => {
      setFoxResults(100);
      if (container)
        container.innerHTML = "";
      let ggg = faucetgroup;
      let rrr = todisplay;
      if (rrr) {
        let ppp = JSON.parse(rrr)!;
        let foxcount = 0;
        let foxlength = ppp.length;
        //get total length
        for (let i = 0; i < foxlength; i++) {
          if (ppp[i] !== undefined) if (ppp[i].origin.data.map.subTypeData.collectionId === "1611d956f397caa80b56bc148b4bce87b54f39b234aeca4668b4d5a7785eb9fa_0") {
            foxcount++;
          }
        }


        setNumResults(foxcount);
        let d = 0;
        let displayfaucetfoxestemp = [];
        for (let i = 0; i < 50; i++) {
          if (ppp[i] !== undefined) if (ppp[i].origin.data.map.subTypeData.collectionId === "1611d956f397caa80b56bc148b4bce87b54f39b234aeca4668b4d5a7785eb9fa_0") {

            // fox
            displayfaucetfoxestemp[d] = { img: "", name: "" }
            displayfaucetfoxestemp[d].img = "https://ordfs.network/content/" + ppp[i].origin.outpoint;
            displayfaucetfoxestemp[d].name = ppp[i].origin.data.map.name;
            displayfaucetfoxestemp[d].link = "https://alpha.1satordinals.com/outpoint/" + ppp[i].origin.outpoint + "/inscription";
            displayfaucetfoxestemp[d].imgid = ppp[i].origin.outpoint;
            displayfaucetfoxestemp[d].outpoint = ppp[i].outpoint;
            displayfaucetfoxestemp[d].trait1 = ppp[i].origin.data.map.subTypeData.traits[0].value;
            displayfaucetfoxestemp[d].trait2 = ppp[i].origin.data.map.subTypeData.traits[1].value;
            displayfaucetfoxestemp[d].trait3 = ppp[i].origin.data.map.subTypeData.traits[2].value;
            displayfaucetfoxestemp[d].trait4 = ppp[i].origin.data.map.subTypeData.traits[3].value;
            displayfaucetfoxestemp[d].trait5 = ppp[i].origin.data.map.subTypeData.traits[4].value;
            displayfaucetfoxestemp[d].trait6 = ppp[i].origin.data.map.subTypeData.traits[5].value;
            displayfaucetfoxestemp[d].trait7 = ppp[i].origin.data.map.subTypeData.traits[6].value;
            displayfaucetfoxestemp[d].owner = ppp[i].owner;
            let own = ppp[i].owner;
            displayfaucetfoxestemp[d].ownerlink = "https://whatsonchain.com/address/" + own;
            displayfaucetfoxestemp[d].ownertrimmed = own.substring(0, 10) + "...";

            //get group member title by image id in foxgroup 
            for (let k = 0; k < ggg.group.length; k++) {
              if (ggg.group[k]["origin.outpoint"] === ppp[i].origin.outpoint) {
                displayfaucetfoxestemp[d].title = ggg.group[k].foxData.foxName;
                // console.log(displayfaucetfoxestemp[d].title )
              }
            }
            d++;
          }
        }
        setDisplayFaucetFoxes(displayfaucetfoxestemp)



        //show/hide display more as necessary
        if ((foxcount - 50) > 0) {
          setDisplayShowMore(true)
        } else {
          setDisplayShowMore(false)
        }

        //no foxes message
        if (foxcount === 0) {
          if (container === null) {
            return
          }
          if ((background === "all") && (name === "all") && (body === "all") && (mouth === "all") && (head === "all") && (eyes === "all") && (item === "all")) {
            container.innerHTML = "<p>There are no foxes in the faucet!</p>";
          } else {
            container.innerHTML = "<h3>There aren't any foxes in the faucet with these attributes:</h3><p>Background:   " + background + "<br />Fox:   " + name + "<br />Body:   " + body + "<br />Mouth:   " + mouth + "<br />Head Item:   " + head + "<br />Eyes:   " + eyes + "<br />Item:   " + item + "</p>";

          }
        }
      }
      // setLoading(false)


    },
    [todisplay] // with dependency: run every time variable changes
  )




  return (
    <>
      {!showgetfoxscreen &&
        <>
          <div className="H3Wrapper">
            <h3>Results: {numresults} / {totalresults}</h3>
          </div>
          <div className="Foxplorer">
            <br />
            <div className="CenterLoader">
              {loading && (
                <>
                  <PulseLoader color="#ffffff" />
                </>
              )}
            </div>

            <ul id="image-container">

              {displayfaucetfoxes &&

                <>

                  {displayfaucetfoxes.map(function (data) {

                    return (
                      <li key={uuidv4()}><a target="blank"
                        href={data.link}>
                        <img src={data.img}
                          className="seventraitfoxes"
                          id={data.imgid} />
                      </a>
                      <span className="ResultsName"><a target="blank"
                      href={data.link}>{data.name}</a>
                    </span>
                        <div className="ResultsTraits">{data.trait1}<br />{data.trait2}<br />{data.trait3}<br />{data.trait4}<br />{data.trait5}<br />{data.trait6}<br />{data.trait7}</div>

                        {/* <div className="twintraitslabels">Background:<br />Fox:<br />Body:<br />Mouth:<br />Head:<br />Eyes:<br />Item:
                    </div>
                    <div className="twintraits">{data.trait1}<br />{data.trait2}<br />{data.trait3}<br />{data.trait4}<br />{data.trait5}<br />{data.trait6}<br />{data.trait7}</div> */}
                        {faucetvar && (available.includes(data.imgid)) && !(justtaken.includes(data.imgid)) &&
                          <Button className="ButtonPadded" variant="contained" data-title={data.title} data-trait1={data.trait1} data-trait2={data.trait2} data-trait3={data.trait3} data-trait4={data.trait4} data-trait5={data.trait5} data-trait6={data.trait6} data-trait7={data.trait7} data-imgid={data.imgid} data-link={data.link} data-img={data.img} data-name={data.name} data-owner={data.owner} data-owner-link={data.ownerlink} data-owner-trimmed={data.ownertrimmed} data-outpoint={data.outpoint} onClick={getFox} sx={{ color: "#fefefe" }}>Get Fox</Button>
                        }
                        {faucetvar && !(available.includes(data.imgid)) &&
                          <Button className="ButtonDisabled" sx={{ color: "#8b8b8b" }} onClick={taken}>Taken</Button>

                        }
                        {faucetvar && (justtaken.includes(data.imgid)) &&
                          <Button className="ButtonDisabled" sx={{ color: "#8b8b8b" }} onClick={taken}>Taken</Button>
                        }
                      </li>
                    )

                  })}

                </>
              }

            </ul>
            <p id="error-container"></p>
            {displayshowmore && (
              <>
                <div id="ShowMore">
                  <ShowMoreButton onClick={showMore} />
                </div>
              </>
            )}

            <a className="WhiteClear" onClick={resetLoading}><u>Reset Filters</u></a>
            {setsearchloading && (
              <>
                <span className="ResetLoaderCenter">
                  <PulseLoader color="#ffffff" />
                </span>
              </>
            )}
            <br />
          </div>



          <TwinFinderFaucet available={available} faucetvar={faucetvar} todisplay={todisplay} totalresults={totalresults} passedFunction={passedFunction} getFox={getFox} />

        </>
      }
      {showgetfoxscreen &&
        <>
          <br /><br /><br />
          <div className="GettingFox">
            <div className="Foxplorer">
              <div className="CenterLoader">
                <ul id="get-fox-image-container">
                  <li><a target="blank"
                    href={getfoxlink}>
                    <img src={getfoximg}
                      id={getfoximgid} />
                  </a>

                    <span className="TwinName"><a target="blank"
                      href={getfoxlink}>{getfoxname}</a><br />
                    </span>
                    <div className="twintraitslabels">Background:<br />Fox:<br />Body:<br />Mouth:<br />Head:<br />Eyes:<br />Item:
                    </div>
                    <div className="twintraits">{trait1}<br />{trait2}<br />{trait3}<br />{trait4}<br />{trait5}<br />{trait6}<br />{trait7}</div>
                  </li>
                </ul>

                {foxloading && (
                  <>
                    <h3>Getting Fox</h3>
                    <PulseLoader color="#ffffff" />
                  </>
                )}
                <div id="get-fox-status-container"><h3>{statusword}</h3>{errormessage}<br /><a className="White" target="blank" href={txidurl}>{foxserverresponse}</a></div>
                {getanother && (
                  <>
                    <GetAnotherButton onClick={backToResults} />
                  </>
                )}

              </div>

              <br />
            </div>

          </div>
        </>
      }
    </>
  )
};

export default CommunityFaucetSearchResults;